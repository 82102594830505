import HS from './common'
import $ from 'jquery'

$(function () {
  //on external pages which have status-page.js included as part of app.pkg.js, skip this
  //also skip this if running in an iframe
  if (
    !window.hsGlobal ||
    HS.Utils.Main.inIframe() ||
    hsGlobal.RUNNING_UNIT_TESTS ||
    !hsGlobal.memberId
  ) {
    return
  }
  // Create StatusIndicator Class
  var StatusIndicator = function (options) {
    this.options = {
      el: null,
      trigger: null,
    }

    this.options = _.extend(this.options, options)
    if (!this.options.el || !this.options.trigger) {
      return
    }

    this.initialize().events().render().resetStatus()

    return this
  }

  StatusIndicator.prototype.initialize = function () {
    this.$el = $(this.options.el)
    this.$trigger = $(this.options.trigger)

    this.$badge = $('<span class="badge">')
    this.$loader = this.$el.find('.spinner')
    return this
  }

  StatusIndicator.prototype.fetch = function () {
    this.resetStatus()

    this.xhr && this.xhr.abort && this.xhr.abort()
    this.xhr = $.get('/ajax/site-status.json', { dataType: 'json' })
      .then(_.bind(this.renderStatus, this))
      .fail(function (xhr, status) {
        status !== 'abort' &&
          console.error(
            'Status indicator: Failed to reach StatusPage endpoint.'
          )
      })
  }

  StatusIndicator.prototype.events = function () {
    this.$trigger.on('shown.bs.dropdown', this.fetch.bind(this))
    return this
  }

  StatusIndicator.prototype.resetStatus = function () {
    // Resets the rendering of the loader/badge
    this.$badge.hide()
    this.$loader.show()
    return this
  }

  StatusIndicator.prototype.showStatus = function () {
    this.$loader.hide()
    this.$badge.show()
    return this
  }

  StatusIndicator.prototype.render = function () {
    this.$el.append(this.$badge)
    return this
  }

  StatusIndicator.prototype.getStatusData = function (status) {
    switch (status.toLowerCase()) {
      case 'minor':
        return {
          className: 'warning',
          label: 'Degraded',
        }
      case 'major':
      case 'critical':
        return {
          className: 'error',
          label: 'Outage',
        }
      default:
        return {
          className: 'success',
          label: 'Up',
        }
    }
  }

  StatusIndicator.prototype.renderStatus = function (response) {
    if (!response || !response.status) {
      return false
    }

    var data = this.getStatusData(response.status.indicator)
    this.$badge
      // Resets status class
      .removeClass()
      // Adds new status class
      .addClass('badge')
      .addClass(data.className)
      // Adds status label
      .html(data.label)
      .attr('title', response.status.description)

    this.showStatus()

    return this
  }

  return new StatusIndicator({
    el: '#status-ind',
    trigger: 'li.helpIcon',
  })
})
