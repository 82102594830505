;(function ($) {
  $.noty.themes.defaultTheme = {
    name: 'defaultTheme',
    helpers: {},
    modal: {
      css: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        backgroundColor: '#000',
        zIndex: 1000,
        opacity: 0.5,
        display: 'none',
        left: 0,
        top: 0,
      },
    },
    style: function () {
      this.$bar.css({
        opacity: 0,
      })
      if (
        this.options.type &&
        this.options.type != 'alert' &&
        this.options.type != 'notification'
      ) {
        this.$bar.addClass(this.options.type)
      }
    },
    callback: {
      onShow: function () {},
      onClose: function () {},
    },
  }
})(jQuery)
