const Constants = {
  Status: {
    ACTIVE: 1,
    PENDING: 2,
    CLOSED: 3,
    SPAM: 4,
  },
  threadType: {
    MESSAGE: 'message',
    CUSTOMER: 'customer',
    NOTE: 'note',
    FORWARD: 'forwardparent',
    FORWARD_CHILD: 'forwardchild',
    PHONE: 'phone',
    CHAT: 'chat',
    BEACON_CHAT: 'beaconchat',
  },
  ticketType: {
    EMAIL: 'email',
    CHAT: 'chat',
    PHONE: 'phone',
  },
  getThreadTypeFromTicketType: function (ticketType) {
    switch (ticketType) {
      case Constants.ticketType.CHAT:
        return Constants.threadType.CHAT
      case Constants.ticketType.PHONE:
        return Constants.threadType.PHONE
      case Constants.ticketType.EMAIL:
      default:
        return Constants.threadType.MESSAGE
    }
  },
  customerEntryType: {
    CHAT: 'chat',
    EMAIL: 'email',
    PHONE: 'phone',
    PROFILE: 'profile',
    SITE: 'site',
  },
  tagColor: [
    '',
    'green',
    'blue',
    'yellow',
    'purple',
    'red',
    'orange',
    'teal',
    'pink',
  ],
  threadState: {
    PUBLISHED: 0,
    DRAFT: 1,
    UNDER_REVIEW: 2,
    HIDDEN: 3,
  },
  ticketOwner: {
    ANYONE: 1,
  },
  ticketAssign: {
    ANYONE: 1,
    PERSON_REPLYING_UNASSIGNED: 2,
    PERSON_REPLYING: 3,
  },
  userType: {
    SYSTEM: 1,
    BACKEND: 2,
    MEMBER: 3,
    TEAM: 4,
  },
  uploads: {
    maxSize: 10 * 1048576, // 10 MB, 1048576=1MB
  },
  Folder: {
    UNASSIGNED: 1,
    MINE: 20,
    NEEDS_ATTENTION: 25,
    DRAFTS: 30,
    ASSIGNED: 40,
    CLOSED: 60,
    SPAM: 80,
    SMART: 100,
    DELETED: 110,
    TEAM: 120,
    LATER: 130,
  },
  Properties: {
    TRAFFIC_COP: 1,
    WORKFLOWS: 2,
    API: 3,
    REPORTS: 4,
    USER_TAGS: 5,
    USER_REPLIES: 6,
    USER_WORKFLOWS: 7,
    DOCS_INSTALLED: 9,
    DOCS_PUBLIC_SITE: 10,
    TICKET_NUM_SUBJECT: 11,
    MAX_EMAIL_DAYS: 12,
    API_INSTALLED: 14,
    NEW_REALTIME: 15,
    CUSTOM_FIELDS: 13,
    CUSTOM_FIELDS_MAX: 16,
    TEAMS: 19,
    TEAM_MEMBER_MAX: 21,
    REPORT_VIEWS: 20,
  },
}

export default Constants
