import Selectize from '../selectize'

/**
 * Selectize Plugin to add dropdown animation via CSS classes
 */
Selectize.define('css_transition', function () {
  this.on('initialize', function () {
    var $select = this.$input
    var $dropdown = $select
      .siblings('.selectize-control')
      .find('.selectize-dropdown')
    var timer
    $dropdown.addClass('selectize-dropdown__animated')
    this.on('dropdown_open', function () {
      // It needs a split sec before showing
      clearTimeout(timer)
      timer = setTimeout(function () {
        $dropdown.addClass('open-forced open-transition')
      }, 50)
    })

    this.on('dropdown_close', function () {
      clearTimeout(timer)
      $dropdown.removeClass('open-forced open-transition')
    })
  })
})
