import '../vendors/jstorage'

/**
 * @description Wrapper object for jStorage methods. Preppends the companyId to every key to avoid naming collision
 * @type        {Object}
 * @requires    jStorage
 * @public
 * @static
 */
const LocalStorage = {
  signKey: function (key) {
    return key ? hsGlobal.companyId + ':' + key : key
  },
  //log the time it takes to set a local storage value, to enable throttling of read/writes for slow tabs
  set: function (key, value, options) {
    var start = new Date()
    var returnValue = $.jStorage.set(this.signKey(key), value, options)
    LocalStorage.writeSpeed = new Date() - start
    return returnValue
  },
  get: function (key, def) {
    return $.jStorage.get(this.signKey(key), def)
  },
  deleteKey: function (key) {
    return $.jStorage.deleteKey(this.signKey(key))
  },
  setTTL: function (key, ttl) {
    return $.jStorage.setTTL(this.signKey(key), ttl)
  },
  getTTL: function (key) {
    return $.jStorage.getTTL(this.signKey(key))
  },
  flush: function () {
    return $.jStorage.flush()
  },
  index: function () {
    return $.jStorage.index()
  },
  storageSize: function () {
    return $.jStorage.storageSize()
  },
  currentBackend: function () {
    return $.jStorage.currentBackend()
  },
  reInit: function () {
    return $.jStorage.reInit()
  },
  storageAvailable: function () {
    return $.jStorage.storageAvailable()
  },
  subscribe: function (channel, callback) {
    return $.jStorage.subscribe(channel, callback)
  },
  publish: function (channel, payload) {
    return $.jStorage.publish(channel, payload)
  },
  listenKeyChange: function (key, callback) {
    return $.jStorage.listenKeyChange(this.signKey(key), callback)
  },
  stopListening: function (key, callback) {
    return $.jStorage.stopListening(this.signKey(key), callback)
  },
}

export default LocalStorage
