import Controller from './controller'
import { Class } from 'jsface'
import './controller'
import Mousetrap from 'mousetrap'

const Keyboard = {}

Keyboard.Utils = new Class({
  $singleton: true,
  main: function () {
    Controller.StateMachine.registerModule(
      'Keyboard',
      [
        { state: 'Open', initCB: () => {}, endCB: () => {} },
        { state: 'Closed', initCB: () => {}, endCB: () => {} },
        { state: 'Dropdown', initCB: () => {}, endCB: () => {} },
      ],
      'Open'
    )
  },
  async: function (asyncFunction) {
    setTimeout(asyncFunction, 0)
  },
  inViewport: function (el) {
    var top = el.offsetTop,
      left = el.offsetLeft,
      width = el.offsetWidth,
      height = el.offsetHeight,
      xOff = window.pageXOffset,
      yOff = window.pageYOffset

    while (el.offsetParent) {
      el = el.offsetParent
      top += el.offsetTop
      left += el.offsetLeft
    }
    return (
      top >= yOff &&
      left >= xOff &&
      top + height <= yOff + window.innerHeight &&
      left + width <= xOff + window.innerWidth
    )
  },
  bindMulti: function (bindDict, event) {
    if (event) {
      Mousetrap.bind(bindDict, event)
    } else {
      Mousetrap.bind(bindDict)
    }
  },
  bindGlobal: function (keyCombo, callback, event) {
    if (event) {
      Mousetrap.bindGlobal(keyCombo, callback, event)
    } else {
      Mousetrap.bindGlobal(keyCombo, callback)
    }
  },
  bindKey: function (keyCombo, callback, event) {
    if (event) {
      Mousetrap.bind(keyCombo, callback, event)
    } else {
      Mousetrap.bind(keyCombo, callback)
    }
  },
  pause: function () {
    Mousetrap.pause()
  },
  unpause: function () {
    Mousetrap.unpause()
  },
  reset: function () {
    Mousetrap.reset()
  },
  unbind: function (keyCombo) {
    Mousetrap.unbind(keyCombo)
  },

  unbindMulti: function (keyCombos, event) {
    _.each(keyCombos, function (combo) {
      Mousetrap.unbind(combo, event)
    })
  },
  trigger: function (keyCombo) {
    Mousetrap.trigger(keyCombo)
  },
})

Keyboard.Registry = {
  store: {
    name: '',
    active: false,
  },
  start: function (name) {
    if (this.store.active === true) {
      var errorMessage =
        'Warning: Attempted to start keyboard module ' +
        name +
        ' before stopping active module ' +
        this.store.name +
        '.'
      console.error(errorMessage)
    }
    this.store.name = name
    this.store.active = true
  },
  stop: function () {
    this.store.active = false
  },
}

Keyboard.Global = new Class(function () {
  return {
    $singleton: true,
    moduleName: 'KeyboardGlobal',
    main: function () {
      // Move code so it can be reused after object is initialized & proxy
      // here.
      this.start()
    },

    start: function () {
      // No need to register bindings if member turned off keyboard shortcuts
      // features does not exist on login window so we double check & go with the default
      // of the keyboard shortcuts being turned on
      if (
        hsGlobal.hasOwnProperty('features') &&
        !hsGlobal.features.isKbShortcutsEnabled
      ) {
        return
      }

      Controller.StateMachine.registerModule(
        this.moduleName,
        [
          { state: 'Open', initCB: () => {}, endCB: () => {} },
          { state: 'Dropdown', initCB: () => {}, endCB: () => {} },
          { state: 'Closed', initCB: () => {}, endCB: () => {} },
        ],
        'Open'
      )

      Keyboard.Utils.bindMulti(
        {
          'shift+/': this.keybindModal,
          z: this.undoAction,
          ',': this.settingsDropdown,
          'g h': this.dashboard,
          'g+h': this.dashboard, // This is a hack to get "g" then "h" to work TODO - dig into Mousetrap and fix this the right way
          enter: this.publishEnter,
          '/': this.startSearch,
          '@': this.startNotifications,
        },
        'keydown'
      )

      Keyboard.Utils.bindGlobal('escape', this.publishEscape, 'keyup')
    },

    stop: function () {
      // No need to cleanup bindings if member turned off keyboard shortcuts
      if (!hsGlobal.features.isKbShortcutsEnabled) {
        return
      }
      Controller.StateMachine.deleteModule(this.moduleName)
      Keyboard.Utils.unbindMulti(
        ['shift+/', 'z', ',', 'g h', 'g+h', 'enter', '/', '@'],
        'keydown'
      )
      Keyboard.Registry.stop()
    },

    publishEnter: function (e) {
      if (Controller.StateMachine.modules.KeyboardGlobal.state === 'Dropdown') {
        Controller.PubSub.publish('User:Keyboard:Dropdown:Activate', null)
      }
    },

    dashboard: function () {
      window.location = $('.nav-main li a.dashboard').attr('href')
    },

    publishEscape: function (e) {
      Controller.PubSub.publish('User:Keyboard:Escape', null)
    },
    startSearch: function (e) {
      e && e.preventDefault()
      if (
        !e.shiftKey &&
        Controller.StateMachine.modules['Keyboard'].state === 'Open' &&
        Controller.StateMachine.modules['KeyboardGlobal'].state === 'Open'
      ) {
        var $searchAutocomplete = $('.search-autocomplete')
        if (!$searchAutocomplete.length) {
          return $('#searchField').trigger('focus')
        }
        return $searchAutocomplete.find('.search-trigger').trigger('click')
      }
    },
    startNotifications: function (e) {
      e && e.preventDefault()
      if (
        Controller.StateMachine.modules['Keyboard'].state === 'Open' &&
        Controller.StateMachine.modules['KeyboardGlobal'].state === 'Open'
      ) {
        var $notificationHistory = $('#notification-history')
        if (!$notificationHistory.length) {
          return // Prevent opening if app does not exist
        }
        return $('.c-notification-dropdown .dropdown-toggle').trigger('click')
      }
    },
    //open keyboard shortcuts modal
    keybindModal: function () {
      if (
        Controller.StateMachine.modules['Keyboard'].state === 'Open' &&
        Controller.StateMachine.modules['KeyboardGlobal'].state === 'Open'
      ) {
        $('#keybindFrame').trigger('click')
        setTimeout(function () {
          $('#fancybox-frame')
            .contents()
            .bind('keydown', function (e) {
              if (e.which === 27 || e.keyCode === 27) {
                $('#fancybox-close').trigger('click')
              }
            })
        }, 2500)
      }
    },
    undoAction: function () {
      var undoLink = $('a.undo')
      if (undoLink.length > 0) {
        //TODO extract undo
        document.location.href = undoLink.attr('href')
      }
    },
    //open any visible settings dropdown
    settingsDropdown: function (e) {
      if (e && !e.metaKey && !e.ctrlKey) {
        $('a:has(i.icon-gear):visible').click()
      }
    },
  }
})

export default Keyboard
