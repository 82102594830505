/**
 * @description Wrapper object for sessionStorage. Preppends the companyId to every key to avoid naming collision
 * @type        {Object}
 * @public
 * @static
 */
const SessionStorage = {
  signKey: function (key) {
    return key ? `${hsGlobal.companyId}:${key}` : key
  },
  //log the time it takes to set a local storage value, to enable throttling of read/writes for slow tabs
  set: function (key, value) {
    const start = new Date()
    window.sessionStorage.setItem(this.signKey(key), value)
    SessionStorage.writeSpeed = new Date() - start
    return value
  },
  get: function (key, def) {
    return window.sessionStorage.getItem(this.signKey(key), def)
  },
  deleteKey: function (key) {
    return window.sessionStorage.removeItem(this.signKey(key))
  },
}

export default SessionStorage
