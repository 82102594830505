;(function ($) {
  //custom container uses 'inline' layout
  $.noty.layouts.inline = {
    name: 'inline',
    options: {
      // overrides options
    },
    container: {
      object: '<ul id="noty_inline_container" />',
      selector: 'ul#noty_inline_container',
      style: function () {
        /*$(this).css({
					left: ($(window).width() - $(this).outerWidth(false)) / 2 + 'px'
				});*/
      },
    },
    parent: {
      object: '<li />',
      selector: 'li',
      css: {},
    },
    css: {},
    addClass: '',
  }
})(jQuery)
