import HS from './common'
import Backbone from 'hs-backbone'
import { Class } from 'jsface'
/**
 * @Class Utils
 *
 * Methods for data manipulation etc that don't belong in any other object,
 * a random underscore like toolkit
 *
 * @type {Class}
 */
/**
 * @class       State Class used for StateMachine Module States
 * @description Utility methods for controller classes and singletons
 * @requires    Class
 * @requires    HS.Utils
 * @type        {Class}
 **/
const Controller = Class(
  /** @lends Controller **/ {
    /**
     * @memberOf    Controller
     * @type        {Boolean}
     * @private
     * @static
     * @field
     **/
    $singleton: true,
    /**
     * @description Filler function for required callbacks etc.
     * @memberOf    Controller
     * @returns     {Void}
     * @example     $('button:first').on('hover', noop, function (event) {
     *                  console.log(event);
     *              });
     * @public
     * @static
     * @function
     * @type {Function}
     **/
    noop: function () {},
    /**
     * @memberOf    Controller
     * @description A utility function used to parse the PubSub channel strings.
     * @param       {String}    string  the string which will be tested for ending with an asterisk.
     * @returns     {Boolean}   If the string ends in an asterisk as true or false
     * @example     var IsAsterisk = Controller.asteriskEnd("App:Event:Click*"); // true
     * @public
     * @function
     * @static
     **/
    asteriskEnd: function (string) {
      return string.slice(string.length - 1) === '*'
    },
    /**
     * @memberOf    Controller
     * @description A utility function used to parse the PubSub channel strings.
     * @param       {String}    string  the string which will be returned with no asterisk at the end.
     * @returns     {String|Boolean}
     * @example     Controller.removeAsteriskEnd("App:Event:Click*") // App:Event:Click
     * @public
     * @function
     * @static
     **/
    removeAsteriskEnd: function (string) {
      if (this.asteriskEnd(string)) {
        return string.slice(0, string.length - 1)
      } else {
        return false
      }
    },
  }
)

/**
 * @class       State Class used for StateMachine Module States
 * @description A state for a method, which has a name and 2 callbacks which are used to help ease the work done by pubsub workers
 * @see         Controller.StateMachine.Channel
 * @requires    Class
 * @requires    Controller
 * @requires    Controller.StateMachine
 * @property    state          {String}    The name of the primary state (Required)
 * @property    secondState    {String}    The name of the secondary state (Optional)
 * @property    initCallback   {Function}  Function called when the state is started
 * @property    endCallback    {Function}   Function called when the state is ended
 **/
export const State = new Class(
  /* @lends State */ {
    /**
     * @memberOf    State
     * @description The constructor for the State class which is used in the StateMachine.
     *              it stores callbacks that are executed when the states is triggered on or off.
     * @param       {String}    state The name of module state
     * @param       {Function}  cb1   The callback executed when the module changes to this state.
     * @param       {Function}  cb2   The callback executed when the module changes to another state from this state.
     * @returns     {Void}
     * @constructor
     * @function
     * @static
     * @private
     **/
    constructor: function (state, cb1, cb2) {
      this.state = state
      this.secondState = ''

      if (cb1) {
        this.initCallback = cb1
      }

      if (cb2) {
        this.endCallback = cb2
      }
    },
    /**
     * @memberOf    State
     * @description this method deletes the initCallback property from the class leaving the value as null
     * @returns     {Void}
     * @function
     * @public
     * @static
     **/
    deleteInitCB: function () {
      this.initCallback = null
    },
    /**
     * @memberOf    State
     * @description this method deletes the endCallback
     *              property from the class leaving the value as null
     * @returns     {Void}
     * @function
     * @public
     * @static
     */
    deleteEndCB: function () {
      this.endCallback = null
    },
    /**
     * @memberOf    State
     * @description set the end state callback to the newCallback param
     * @param       {Function} newCallback The new callback that will be used for the init callback for this state
     * @returns     {Void}
     * @function
     * @public
     * @static
     */
    setInitCB: function (newCallback) {
      this.initCallback = newCallback
    },
    /**
     * @memberOf    State
     * @description set the end state callback to the newCallback param
     * @param       {Function}  newCallback The new callback that will be used for the init callback for this state.
     * @returns     {Void}
     * @function
     * @public
     * @static
     */
    setEndCB: function (newCallback) {
      this.endCallback = newCallback
    },
    /**
     * @memberOf    State
     * @description Change the name of the state for future uses.
     * @param       {String} newName The new name for the state property
     * @returns     {Void}
     * @function
     * @public
     * @static
     **/
    changeName: function (newName) {
      this.state = newName
    },
  }
)
/**
 * @class       ActionStack is used for controlling task execution based on Last In First Out order
 * @type        {Class}
 * @requires    Class
 * @requires    Controller.StateMachine
 * @see         Controller.StateMachine.escapeStack
 * @description The actionStack class is used for controlling the order of task execution and is based on Last In First Out.
 * @property    defaultAction   {Function|Undefined}
 * @property    pairedAction    {Function|Undefined}
 * @property    stack           {Object[]}
 * @property    channels        {Object}
 **/
const ActionStack = new Class(
  /* @lends ActionStack */ {
    /**
     * @memberOf    ActionStack
     * @returns     {Void}
     * @description constructor function for the ActionStack. Currently the only instance of it is the escapeStack found
     *              in Controller.StateMachine.
     * @see         Controller.StateMachine.escapeStack
     * @param       {Function}  defaultAction   The default action taken when stack finishes executing its last function.
     *              This could be described as the finish callback.
     * @param       {Function}   pairedAction   An action that is executed with all the stack functions. All functions will
     *              execute this after they have finished executing.
     * @static
     * @constructor
     * @function
     * @private
     */
    constructor: function (defaultAction, pairedAction) {
      this.stack = []
      this.channels = {}

      if (typeof defaultAction === 'function') {
        this.defaultAction = defaultAction
      } else {
        this.defaultAction = undefined
      }
      if (typeof pairedAction === 'function') {
        this.pairedAction = pairedAction
      } else {
        this.pairedAction = undefined
      }
    },
    /**
     * @memberOf    ActionStack
     * @description Pushes an action into the action stack making it the next action line unless another action is added.
     * @param       {Object}    data    this is the data used for the stack entry.
     *              object parameters
     *                  event: {String|Function},
     *                  data: {Object},
     *                  context: {Object}
     * @returns     {Number} returns the id of the stack item which is based on the new objects position in the stack.
     * @function
     * @static
     * @public
     */
    addToStack: function (data) {
      if (typeof data.event === 'function' || typeof data.event === 'string') {
        var id = this.stack.length
        this.stack.push({
          id: id,
          event: data.event,
          data: data.data,
          context: data.context,
        })
        return id
      } else {
        return -1
      }
    },
    /**
     * @memberOf    ActionStack
     * @description Removes an action from the stack based on the actions position in the array.
     * @param       {Number}    stackId this is the data used for the stack entry.
     * @returns     {Number|Boolean} returns the id of the stack item which is based on the new objects position in the stack.
     * @function
     * @static
     * @public
     */
    removeFromStack: function (stackId) {
      if (this.stack[stackId] && this.stack[stackId].id === stackId) {
        this.stack[stackId] = null
        return stackId
      } else {
        return false
      }
    },
    /**
     * @memberOf    ActionStack
     * @description Starts the execution of actions currently in the action stack starting at the
     *              0-array location and then clear out the array when finished.
     * @returns     {Void}
     * @function
     * @static
     * @public
     */
    startStack: function () {
      this.executeStack()
    },
    /**
     * @memberOf    ActionStack
     * @description Sets the default action which will be executed when an actionStack is empty
     * @param       {Function}  newDefaultAction The new default action which will now
     *              be executed when a stack is finished.
     * @returns     {Void}
     * @function
     * @static
     * @public
     */
    setDefaultAction: function (newDefaultAction) {
      this.defaultAction = newDefaultAction
    },
    /**
     * @memberOf    ActionStack
     * @description Sets the paired action which will be executed after any of action is finished executing.
     * @param       {Function}  newPairedAction The new paired action which will now be
     *              executed when a stack action has been completed.
     * @returns     {Void}
     * @function
     * @static
     * @public
     */
    setPairedAction: function (newPairedAction) {
      this.pairedAction = newPairedAction
    },
    /**
     * @memberOf    ActionStack
     * @description Clears the stack data
     * @param       {Boolean}   returnStack Specifies if the cleared out stack should be returned or it should not return anything.
     * @returns     {Object|Undefined} Contains either the old stacks data or undefined.
     * @function
     * @static
     * @public
     **/
    clearStack: function (returnStack) {
      if (!returnStack) {
        returnStack = false
      }

      if (returnStack === true && returnStack === false) {
        return undefined
      }

      if (this.stack.length === 0) {
        if (returnStack === true) {
          return this.stack
        } else if (returnStack === false) {
          return undefined
        }
      }

      var oldStack = this.stack
      this.stack = []

      if (returnStack === true) {
        return this.stack
      }
    },
    /**
     * @memberOf    ActionStack
     * @description runs the action at the location stated by the stackId parameter.
     * @param       {Number}    stackId The stackId that will be executed.
     * @returns     {Number|Boolean} the stackId from the stack action that was executed or false if there is no action to run.
     * @requires    Controller.PubSub.publish
     * @function
     * @static
     * @public
     */
    runStack: function (stackId) {
      var event = this.stack[stackId].event,
        success = 0

      if (typeof event === 'string') {
        if (!this.stack[stackId].context) {
          Controller.PubSub.publish(event, this.stack[stackId].data)
          success = this.removeFromStack(stackId)

          if (this.pairedAction) {
            this.pairedAction.call(
              this.stack[stackId].context,
              this.stack[stackId].data
            )
          }
        }
      } else if (typeof event === 'function') {
        if (this.stack[stackId].context) {
          event.call(this.stack[stackId].context, this.stack[stackId].data)

          if (this.pairedAction) {
            this.pairedAction.call(
              this.stack[stackId].context,
              this.stack[stackId].data
            )
          }
        } else {
          event(this.stack[stackId].data)

          if (this.pairedAction) {
            this.pairedAction(this.stack[stackId].data)
          }
        }

        success = this.removeFromStack(stackId)
      } else {
        return false
      }

      if (success === stackId) {
        return stackId
      }
    },
    /**
     * @memberOf    ActionStack
     * @description runs the action at the location stated by the stackId parameter.
     * @param       {Number}    stackId The stackId that will be executed.
     * @returns     {Number|Boolean} returns either the next id for the action to execute or false.
     * @function
     * @static
     * @public
     */
    executeStack: function (stackId) {
      switch (true) {
        case stackId > 0:
          if (this.stack[stackId]) {
            return this.runStack(stackId)
          } else {
            this.executeStack(stackId - 1)
          }
          break
        case stackId === 0 || stackId === -1:
          if (this.stack[0]) {
            return this.runStack(stackId)
          } else {
            if (this.defaultAction) {
              this.defaultAction()
            }
          }
          break
        case stackId === -1:
          var nullArr = true,
            i = this.stack.length - 1
          for (i; i > -1; i--) {
            if (this.stack[i]) {
              nullArr = false
              break
            }
          }
          if (i + 1 === 0 || nullArr === true) {
            if (this.defaultAction) {
              this.defaultAction()
            }
          }

          return false
        case !stackId && stackId !== 0:
          var start = this.stack.length - 1
          if (start !== 0 && !start) {
            start = -1
          }

          this.executeStack(start)
          break
      }
    },
    /**
     * @memberOf    ActionStack
     * @description runs the action at the location stated by the stackId parameter.
     * @param       {String}    chan    Name of channel that going to be added to the channel array
     * @param       {Function}  func    Function that will be used to close the widget if escape is used or close is used
     * @param       {Object}    data    Data passed to the event function when t issi==
     * @param       {object}    context Used as "this" in the action's function
     * @returns     {Object}    returns the channel data that was just created by a plugins opening
     * @function
     * @static
     * @public
     */
    opened: function (chan, func, data, context) {
      if (
        this.channels &&
        this.channels[chan] &&
        this.stack[this.channels[chan]]
      ) {
        this.removeFromStack(this.channels[chan])
      }

      this.channels[chan] = this.addToStack({
        event: func,
        data: data,
        context: context,
      })
      return this.channels[chan]
    },
    /**
     * @memberOf    ActionStack
     * @description Executes the actions needed to close a function
     * @param       {Number} stackId The stackId that will be executed.
     * @returns     {Number|Boolean} the stackId from the stack action that was executed or false if there is no action to run.
     * @function
     * @static
     * @public
     */
    close: function (chan) {
      if (this.channels && this.channels[chan] > -1) {
        this.executeStack(this.channels[chan])
        this.channels[chan] = -1
      }
    },
    /**
     * @memberOf    ActionStack
     * @description signals to the actionStack a certain module is closed an it action will no longer need to be executed.
     * @param       {String} chan The channel/module name that is closed. The string is associated with the stackId
     * @returns     {Void}
     * @function
     * @static
     * @public
     */
    closed: function (chan) {
      if (this.channels && this.channels[chan] > -1) {
        this.removeFromStack(this.channels[chan])
        this.channels[chan] = -1
      }
    },

    closeAll: function (matchFunc, execute) {
      var that = this
      execute = execute === true
      _.each(this.channels, function (index, channelName) {
        if (index > -1 && matchFunc(channelName)) {
          if (execute) {
            that.close(channelName)
          } else {
            that.closed(channelName)
          }
        }
      })
    },

    isOpen: function (chan) {
      return this.channels[chan] != undefined && this.channels[chan] > -1
    },

    isEmpty: function () {
      var escapeStackEmpty = true
      _.each(this.channels, function (value, key) {
        if (value > -1) {
          escapeStackEmpty = false
        }
      })
      return escapeStackEmpty
    },
  }
)

/**
 * @requires    Class
 * @requires    State
 * @requires    ActionStack
 * @type        {Class}
 * @class       StateMachine is used for controlling the states of widgets and determining the outcome of events based on these states
 * @description Manages the App and Modules States and the callbacks associated with them easier to manage
 *              and also furthers the abstraction of the application as a whole.
 * @property    {String} appState Describes the current state of the application. Very general state.
 * @property    {Object} modules
 * @property    {ActionStack} escapeStack
 */
Controller.StateMachine = new Class(
  /* @lends Controller.StateMachine */ {
    /**
     * @memberOf    Controller.StateMachine
     * @type        {Boolean}
     * @private
     * @static
     * @field
     */
    $singleton: true,
    /**
     * @memberOf    Controller.StateMachine
     * @description the main function is executed once the class has been created.
     *              Is basically a constructor for singleton classes.
     * @returns     {Void}
     * @constructs  Controller.StateMachine
     * @private
     * @static
     * @function
     **/
    main: function () {
      var that = this

      this.appState = 'default'
      this.modules = {}
      this.escapeStack = new ActionStack(function () {
        var focusedInput = $('input:focus, select:focus, textarea:focus')

        if (focusedInput.length === 1) {
          focusedInput.first().trigger('blur')
        } else if (focusedInput.length > 1) {
          focusedInput.each(function (idx, el) {
            el.trigger('blur')
          })
        }

        $('body').trigger('click')

        this.clearStack()
      })
    },
    /**
     * @memberOf        Controller.StateMachine
     * @description     This registers a modules with the statemachine. The statemachine begins keeping track of the
     *                  modules state and executes the callbacks associated with the changes it sees.
     * @param           {String}    module      - The name of the module being registered into the system
     * @param           {Object[]}  states      - All the possible states - Array of Strings
     * @param           {String}    currState   - Current state of the module by name
     * @returns         {Boolean}   Success
     * @public
     * @static
     * @function
     **/
    registerModule: function (module, states, currState) {
      if (!this.modules[module]) {
        this.modules[module] = {}
        this.modules[module].name = module
        this.modules[module].states = {}

        if (currState) {
          this.modules[module].state = currState
        }

        var len = states.length,
          i

        for (i = 0; i < len; i++) {
          this.createState(
            module,
            states[i].state,
            states[i].initCB,
            states[i].endCB
          )
        }

        return true
      } else {
        return false
      }
    },
    /**
     * @memberOf    Controller.StateMachine
     * @description This creates a state for a specified module.
     * @param       {String}    module  - The module that will be changing its state
     * @param       {String}    state   - The name of the state the module is in
     * @param       {Function}  cb1     - Callback for when the state starts
     * @param       {Function}  cb2     - Callback for when the state stops
     * @returns     {Boolean}   Success
     * @public
     * @static
     * @function
     **/
    createState: function (module, state, cb1, cb2) {
      if (!this.modules[module].states[state]) {
        this.modules[module].states[state] = new State(state, cb1, cb2)
        return true
      } else {
        return false
      }
    },
    /**
     * @memberOf    Controller.StateMachine
     * @description deletes the module and all related data in it JS object.
     * @param       {String}    module  the name of the module that will be deleted
     * @returns     {Void}
     * @public
     * @static
     * @function
     **/
    deleteModule: function (module) {
      if (this.modules[module]) {
        delete this.modules[module]
      }
    },
    /**
     * @memberOf    Controller.StateMachine
     * @description gets the state of the module name passed in the first argument
     * @param       {String}    module      The module that will be changing its state
     * @returns     {String}    returns the modules current state.
     * @public
     * @static
     * @function
     **/
    getState: function (module) {
      return this.modules[module].state
    },
    /**
     * @memberOf    Controller.StateMachine
     * @description Changes the state of the module as well as triggers the state change callbacks for the 2 states involved
     * @param       {String}    module      The module that will be changing its state
     * @param       {String}    newState    The new state that the module will be using - Must be in states array from registerModule
     * @param       {Object}    data        The module data
     * @returns     {Void}
     * @public
     * @static
     * @function
     **/
    changeState: function (module, newState, data) {
      var oldState = this.modules[module].state
      if (this.modules[module].states[oldState]) {
        if (this.modules[module].states[oldState].endCallback) {
          if (data) {
            this.modules[module].states[oldState].endCallback(data)
          } else {
            this.modules[module].states[oldState].endCallback()
          }
        }
      }

      this.modules[module].state = newState
      if (this.modules[module].states[newState]) {
        if (this.modules[module].states[newState].initCallback) {
          if (data) {
            this.modules[module].states[newState].initCallback(data)
          } else {
            this.modules[module].states[newState].initCallback()
          }
        }
      }
    },
    /**
     * @memberOf        Controller.StateMachine
     * @description     Get a secondary state for modules, if it exists, to allow for more advanced state management
     * @param           {String}    module The state will come from this module if it is available
     * @returns         {String|Boolean}    returns the secondary state of the module
     * @function
     * @public
     * @static
     **/
    getSecondState: function (module) {
      if (this.modules[module]) {
        if (this.modules[module].secondState !== undefined) {
          return this.modules[module].secondState
        } else {
          return false
        }
      } else {
        return false
      }
    },
    /**
     * @memberOf    Controller.StateMachine
     * @description Sets the secondary state for the modules specified, with a newState and data for the callbacks initialized
     * @param       {String} module       The name of the module that will have it's secondary state changed.
     * @param       {String} newState     This is the name of state that the
     * @param       {Object|String|Number|Array} data         This is the name of stat
     * @returns     {Void|Boolean} returns false incase data is missing.
     * @function
     * @public
     * @static
     **/
    setSecondState: function (module, newState, data) {
      if (this.modules[module] && this.modules[module].states[newState]) {
        if (this.modules[module].secondState === '') {
          this.modules[module].secondState = newState

          if (this.modules[module].states[newState]) {
            this.modules[module].states[newState].initCallback(data)
          }
        } else {
          var currState = this.modules[module].secondState

          if (this.modules[module].states[currState]) {
            this.modules[module].states[currState].endCallback(data)
            this.modules[module].secondState = newState

            if (this.modules[module].states[newState]) {
              this.modules[module].states[newState].initCallback(data)
            }
          }
        }
      } else {
        return false
      }
    },
  }
)

/**
 * @class           Represents a Channel
 * @description     Creates a new channel
 * @requires        Class
 * @type            {Class}
 * @property        {String}    channel     Channel name
 * @property        {String[]}  subChannels Sub-Channels published to when publishing to this channel
 * @property        {Boolean}   original    Is this channel a user created channel or a sub channel
 * @property        {String}    splitter    What character is used to parse the channel string into
 * @property        {String}    subscribers What character is used to parse the channel string into
 *                  sections used to create the sub-channels.
 */
const Channel = new Class(
  /* @lends Channel */ {
    /**
     * @constructor
     * @memberOf        Channel
     * @description     Setup a channel class for storing its name and data
     * @param           {String}    channel     The Name of the channel ie "App:Widget:Event"
     * @param           {Boolean}   original    Is this the highest level channel
     * @returns         {Void}
     * @function
     * @static
     * @private
     */
    constructor: function (channel, original) {
      this.channel = channel
      this.original = original
      this.subscribers = []
      this.splitter = ':'

      if (this.original) {
        this.subChannels = []
        this.parseTopics()
      }
    },
    /**
     * @memberOf    Channel
     * @description Delete the channel and ensure all the references to it's data are deleted
     * @returns     {Boolean}   returns true to show that everything has been deleted
     * @function
     * @static
     * @public
     */
    deleteChannel: function () {
      this.channel = null
      this.original = null
      this.subscribers = null
      this.splitter = null
      this.subChannels = null

      return true
    },
    /**
     * @memberOf    Channel
     * @description Clear current subscriptions for the channel
     * @returns     {Void}
     * @function
     * @static
     * @public
     */
    clearSubs: function () {
      this.subscribers = []
    },
    /**
     * @memberOf    Channel
     * @description Clear current subscriptions for the channel
     * @params      {String}    channel The name of the channel that will replace the previous channel name.
     * @returns     {Void}
     * @function
     * @static
     * @public
     */
    changeChannel: function (channel) {
      Controller.PubSub.publish(
        'pubsub:channels:changeChannel',
        {
          prev: this.channel,
          next: channel,
        },
        true
      )

      this.channel = channel
    },
    /**
     * @memberOf    Channel
     * @description parse the topics within the channels name such that even the partial subs will recieve the
     *              channel's messages as expected.
     * @returns     {Void}
     * @function
     * @static
     * @public
     */
    parseTopics: function () {
      'use strict'
      var colonIdx = this.channel.indexOf(':'),
        dotIdx = this.channel.indexOf('.')

      if (colonIdx > -1) {
        this.splitter = ':'
      } else if (dotIdx > -1) {
        this.splitter = '.'
      }

      var channelArr = this.channel.split(this.splitter),
        len = channelArr.length,
        str,
        i,
        x

      for (i = 0; i < len - 1; i++) {
        str = ''

        for (x = 0; x <= i; x++) {
          if (x === 0) {
            str = channelArr[x]
          } else {
            str = str + this.splitter + channelArr[x]
          }
        }

        this.subChannels.push(str)
      }
    },
  }
)
/**
 * @class       PubSub
 * @requires    Controller
 * @requires    Class
 * @requires    Channels
 * @type        {Class}
 * @description Global implementation of PubSub methods used to synchronize states and execute the actions based on events.
 * @property    {Object}   channels
 * @property    {String[]} channelsList
 */
Controller.PubSub = new Class(function () {
  /**
   * @memberOf    Controller.PubSub
   * @description the private function used to parse channels into their sub-channels used for many
   *              methods in this class
   * @param       {String} string the name of the channel that is going to be parsed.
   * @requires    Controller.asteriskEnd
   **@returns     {String}
   * @example     var parsedChannel = this.parseChanel("User:Click:Button");
   * @private
   * @static
   * @function
   **/
  var parseChannel = function (string) {
    if (string.length) {
      var value = Controller.asteriskEnd(string)

      if (value) {
        return value
      } else {
        return ''
      }
    } else {
      return ''
    }
  }
  return {
    /**
     * @memberOf    Controller.PubSub
     * @lends       Controller.PubSub
     * @type        {Boolean}
     * @description states if the class is a singleton and which means the constructor will be private if it is true.
     * @private
     * @static
     * @field
     */
    $singleton: true,
    /**
     * @memberOf    Controller.PubSub
     * @lends       Controller.PubSub
     * @type        {Object}
     * @property    {Function}  async
     * @property    {Function}  hasSubscribers
     * @public
     * @static
     * @field
     **/
    $statics: {
      /**
       * @inner
       * @memberOf    Controller.PubSub
       * @description this function is used to execute a function asynchronously via setTimeout 0
       * @param       {Function}  fn      Required - the function to be executed by the setTimeout
       * @param       {Object}    data    Optional - the data for fn if it is needed
       * @example     Controller.PubSub.async(function () {
       *                  console.log('doing stuff here');
       *              });
       * @public
       * @static
       * @function
       **/
      async: function (fn, data) {
        if (data) {
          setTimeout(function () {
            fn(data)
          }, 0)
        } else {
          setTimeout(function () {
            fn()
          }, 0)
        }
      },
      /**
       * @inner
       * @memberOf    Controller.PubSub
       * @description this function is used determine if a pubsub channel has subscribers at this time.
       * @param       {Function}  channel Required - the name of the channel to get subscriber info from
       * @example     var IsEscapeSubscribed = Controller.PubSub.hasSubscribers("User:Keyboard:Keydown:EscapeKey");
       * @public
       * @static
       * @function
       **/
      hasSubscribers: function (channel) {
        return (
          this.channels[channel] &&
          this.channels[channel].subscribers.length > 0
        )
      },
    },
    /**
     * @memberOf    Controller.PubSub
     * @type        {Object}
     * @description An object containing string: object pairs based on channel names and their respective data.
     * @public
     * @field
     * @inner
     */
    channels: {},
    /**
     * @memberOf    Controller.PubSub
     * @type        {String[]}
     * @description An array containing the names of all the channels create and sub-channel byproducts
     * @public
     * @field
     * @inner
     */
    channelsList: [],
    /**
     * @memberOf    Controller.PubSub
     * @param       {String} channel The name of the channel
     * @description creates a new channel as well as any sub-channels
     * @example     Controller.PubSub.createChannel("User:Event:Hover:Title")
     *              .createChannel("User:Event:Click:Plugin")
     * @returns     {Controller.PubSub} Returns itself so methods can be chained
     * @requires    Channel
     * @public
     * @function
     * @static
     * @inner
     **/
    createChannel: function (channel) {
      if (this.channels[channel]) {
        return this
      } else {
        this.channels[channel] = new Channel(channel, true)
        this.channelsList.push(channel)

        var len = this.channels[channel].subChannels.length,
          i

        for (i = 0; i < len; i++) {
          if (!this.channels[this.channels[channel].subChannels[i]]) {
            this.channels[this.channels[channel].subChannels[i]] = new Channel(
              this.channels[channel].subChannels[i],
              false
            )
            this.channelsList.push(this.channels[channel].subChannels[i])
          }
        }

        return this
      }
    },
    /**
     * @memberOf    Controller.PubSub
     * @see         Controller.PubSub.publish
     * @param       {String} channel The name of the channel
     * @param       {Object} data    used to execute a callback for this channel
     * @description executes the channels subscribers callbacks with a new set of data but
     *              does not execute the sub-channels
     * @example     Controller.PubSub.deliver("User:Event:Click", { scores: [6,5,97,32,68,43,58,64,98,86], IsActive: false })
     * @returns     {Controller.PubSub} Returns itself so methods can be chained
     * @public
     * @function
     * @static
     * @inner
     **/
    deliver: function (channel, data) {
      var len = this.channels[channel].subscribers.length,
        i

      for (i = 0; i < len; i++) {
        if (this.channels[channel].subscribers[i]) {
          this.channels[channel].subscribers[i].callback(data)
        }
      }

      return this
    },
    /**
     * @memberOf    Controller.PubSub
     * @see         Controller.PubSub.deliver
     * @param       {String} channel     The name of the channel
     * @param       {Object} data        used to execute a callback for this channel
     * @description executes the channels subscribers callbacks with a new set of data but
     *              does execute the sub-channels which is how it differs from deliver which is
     *              focused on a single channel
     * @example     Controller.PubSub.deliver("User:Event:Click", { scores: [6,5,97,32,68,43,58,64,98,86], IsActive: false })
     * @returns     {Controller.PubSub} Returns itself so methods can be chained
     * @public
     * @function
     * @static
     * @inner
     **/
    publish: function (channel, data) {
      if (!this.channels[channel] || !this.channels[channel].subscribers) {
        return this
      }

      if (this.channels[channel].subscribers.length) {
        var len = this.channels[channel].subscribers.length,
          i

        for (i = 0; i < len; i++) {
          if (this.channels[channel].subscribers[i]) {
            this.channels[channel].subscribers[i].callback(data)
          }
        }

        if (this.channels[channel].subChannels.length) {
          len = this.channels[channel].subChannels.length

          for (i = 0; i < len; i++) {
            this.deliver(this.channels[channel].subChannels[i], data)
          }
        }
      }
      return this
    },
    /**
     * @memberOf    Controller.PubSub
     * @param       {String} channel The name of the channel
     * @param       {Function} cb Callback function executed when the channel is published to.
     * @see         Controller.PubSub.publish
     * @see         Controller.PubSub.createChannel
     * @description subscribes to and potentially creates a channel while doing so. The callback will be executes when
     *              the channel is published or delivered to
     * @example     Controller.PubSub.subscribe("User:Event:Click", callbackFunction)
     * @returns     {Number|Null} Returns the subscription's id so it can be un-subscribed using this number in the future.
     * @requires    Controller.asteriskEnd
     * @public
     * @function
     * @static
     * @inner
     **/
    subscribe: function (channel, cb) {
      if (Controller.asteriskEnd(channel)) {
        channel = parseChannel(channel)
      }

      if (!this.channels[channel]) {
        this.createChannel(channel)
      }

      if (this.channels[channel].subscribers) {
        this.channels[channel].subscribers.push({
          callback: cb,
        })

        return this.channels[channel].subscribers.length - 1
      } else {
        return null
      }
    },
    /**
     * @memberOf    Controller.PubSub
     * @param       {String} channel     The name of the channel
     * @param       {Number} id          The id of the subscribed function to help find it in the subscribers array..
     * @see         Controller.PubSub.publish
     * @see         Controller.PubSub.createChannel
     * @description subscribes to and potentially creates a channel while doing so. The callback will be executes when
     *              the channel is published or delivered to
     * @example     Controller.PubSub.unsubscribe("User:Event:Click", channelId)
     * @returns     {Controller.PubSub} Returns itself so methods can be chained
     * @public
     * @function
     * @static
     * @inner
     **/
    unsubscribe: function (channel, id) {
      var theChannel = false
      if (channel in this.channels) {
        theChannel = this.channels[channel]
      }
      if (theChannel && theChannel.subscribers[id]) {
        theChannel.subscribers[id] = 0
        return this
      } else {
        return this
      }
    },
  }
})
Controller.ReqRes = new Backbone.Wreqr.RequestResponse()
/**
 * User:Dropdown:OpenNew
 * This channel is used to clear the page as well as trigger other dropdowns to close
 * User:Keyboard:Dropdown:Events
 * This channel is used trigger the passing of events to the open dropdown.
 */
Controller.PubSub.createChannel('User:Dropdown:CloseAll')
Controller.PubSub.createChannel('User:Dropdown:Close')
Controller.PubSub.createChannel('User:Keyboard:Dropdown:Events')
Controller.PubSub.createChannel('User:Dropdown:Open')

export default Controller
