import Selectize from '../selectize'

/**
 * Selectize Plugin that manages the ESC Stack of the dropdown
 */
Selectize.define('esc_stack', function () {
  var stackChannel = 'filter-dropdown'
  this.on('dropdown_open', function ($dropdown) {
    HS.stack.opened(stackChannel, function () {
      var dropdown = $dropdown[0].selectize
      dropdown && dropdown.close()
      HS.stack.closed(stackChannel)
    })
  })

  this.on('dropdown_close', function ($dropdown) {
    $dropdown.trigger('blur')
  })

  this.on('blur', function () {
    HS.stack.closed(stackChannel)
  })
})
