import Selectize from '../selectize'

/**
 * Selectize Plugin that prevents the propagation of the click event
 * when removing an item from the list.
 * This is specially useful when using Selectize inside a dropdown to
 * prevent the dropdown from closing when clicking on the remove icon.
 */
Selectize.define('remove_stop_propagation', function () {
  var selectizeInstance = this
  this.on('initialize', function () {
    this.$control.on('click', '.item .remove', function (e) {
      e.stopPropagation()
    })

    this.$control.on('click', function () {
      selectizeInstance.open()
    })
  })
})
